@import "_variables.scss";

.yn-header-day {
  background-color:var(--yn-body-bg);
}

.yn-vacation-day {
  background-color:var(--yn-blue);
  &.yn-pending-approving {
    opacity: 0.5;
  }
  &.yn-pending-deleting {
    opacity: 0.5;
  }
}

.yn-permission-day {
  background:linear-gradient(to bottom, white 50%, var(--yn-blue) 50%);
  &.yn-pending-approving {
    opacity: 0.5;
  }
  &.yn-pending-deleting {
    opacity: 0.5;
  }
}

.yn-festivity-day {
  background-color: rgba(220, 53, 69, 0.1)
}

.yn-punching-day {
  background-color: #28a745;
}

.yn-punching-permission-day {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#28a745), color-stop(50%,var(--yn-blue)));
}

.yn-rejected-punching {
  background-color: rgba(150, 10, 24, 0.3);
}

.yn-absence-day {
  background-color: #960a18;
}

.yn-cassa-day {
  background-color: #f3e709;
}

.yn-cassa-half-day {
  background:linear-gradient(to bottom, white 50%, #f3e709 50%);
}

.yn-punching-cassa-half-day {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,#28a745), color-stop(50%,#f3e709));
}

.yn-week-resume-day {
  background-color: rgba(242, 250, 136, 0.6);
}

.yn-task {
  color: var(--yn-dark-blue);
  font-size:.85em;
  margin: 1px 2px 0 1px;
  padding: 0 1px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #3788d8;
  width: 150px;
}

#yn-task-table td {
  border-top: 0px;
  border-left: 0px;
  border-right: 1px solid var(--yn-gray-400);
  border-bottom: 1px solid var(--yn-gray-400);
  padding: 0.25rem;
  &>div {
    width: 150px;
    min-height: 50px;
  }
}